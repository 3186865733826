<template>
  <div
    class="about-company">
    <div class="push-inner">
      <company-address :address="address" />
      <product-services
        :description="profiles.Product"
        :ratings="ratings" />
      <competitors
        :description="profiles.Competitors"
        :ratings="ratings" />
      <finance
        :description="profiles.Finance"
        :ratings="ratings" />
      <contact-info
        :ratings="ratings"
        :is-company-profile-gen-ai-enabled="isCompanyProfileGenAiEnabled" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CompanyMap from '~/components/company/about/CompanyMap'
import CompanyAddress from '~/components/company/about/CompanyAddress'
import ProductServices from '~/components/company/about/gen-ai/ProductServices'
import Competitors from '~/components/company/about/gen-ai/Competitors'
import Finance from '~/components/company/about/gen-ai/Finance'
import ContactInfo from '~/components/company/about/ContactInfo'
import { companyProfileGenAiMixin } from '~/mixins/companyProfileGenAiMixin'

export default {
  name: 'CompanyIdAbout',
  components: {
    CompanyMap,
    CompanyAddress
  },
  mixins: [companyProfileGenAiMixin],
  computed: {
    ...mapState('company/about', ['address']),
    ...mapState('company', ['profiles', 'ratings'])
  },
  created() {
    if (this.isCompanyProfileGenAiEnabled) {
      this.$options.components.ProductServices = ProductServices
      this.$options.components.Competitors = Competitors
      this.$options.components.Finance = Finance
    }
    this.$options.components.ContactInfo = ContactInfo
  }
}
</script>
<style src="~/assets/scss/sections/company/about.scss" lang="scss" />
<style src="~/assets/scss/sections/company/ai-gen.scss" lang="scss" />
