<template>
  <div class="company-profile-section competitors-section pl-0 pr-0">
    <div class="company-profile-ai-gen">
      <div class="company-profile-ai-gen-content">
        <div class="row">
          <div class="col-md-4">
            <div class="ai-gen-content-section-header">
              <h2>Competitors</h2>
              <el-popover
                placement="bottom"
                width="300"
                trigger="hover"
                popper-class="ai-gen-popover"
                content="AI-generated content may contain inaccurate information. Please verify the data with external sources. External links are provided for reference and are not affiliated with ImportGenius.">
                <el-button slot="reference"> <span class="gen-ai-icon" /></el-button>
            </el-popover></div>
          </div>
          <div class="col-md-8">
            <div
              :class="{ 'ai-gen-content-clamp': showClass2 }"
              class="ai-gen-content-about-company"
              v-html="description"/>
            <button 
              class="btn-text" 
              @click="clickReadMore2">
              <template v-if="showClass2">
                Show more
              </template>
              <template v-else>
                Show less
              </template>
            </button>
            <div class="company-profile-ai-gen-ratings-section">
              <gen-ai-ratings 
                :rating="ratings.rating" 
                @update-rating="editRating"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from '~/components/custom/vue-content-loader'
import GenAiRatings from '~/components/common/GenAiRatings'
import { mapActions } from 'vuex'

export default {
  components: {
    ContentLoader,
    GenAiRatings
  },
  props: {
    description: {
      type: String,
      required: true,
      default: ''
    },
    ratings: {
      type: Object,
      required: true,
      default: () => ({ rating: 0 })
    }
  },
  data() {
    return {
      showClass2: true,
      showMoreText: 'Show more'
    }
  },
  methods: {
    ...mapActions('company', ['editRating']),
    clickReadMore2() {
      this.showClass2 = !this.showClass2
    }
  }
}
</script>
