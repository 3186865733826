<template>
  <section class="contact-info">
    <div class="row">
      <div class="col-md-4">
        <div class="company-profile-ai-gen">
          <div class="company-profile-ai-gen-content">
            <div class="ai-gen-content-section-header">
              <h2>Contact Info</h2>
              <el-popover
                v-if="isCompanyProfileGenAiEnabled"
                placement="bottom"
                width="300"
                trigger="hover"
                popper-class="ai-gen-popover"
                content="AI-generated content may contain inaccurate information. Please verify the data with external sources. External links are provided for reference and are not affiliated with ImportGenius.">
                <el-button slot="reference"> <span class="gen-ai-icon" /></el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <ContentLoader
            v-if="$actions.pending('company/about/fetchCompanyAbout')"
            :height="80">
            <rect
              x="0"
              y="0"
              rx="0"
              ry="0"
              width="40"
              height="14"/>
            <rect
              x="0"
              y="25"
              width="30"
              height="4"/>
            <rect
              x="0"
              y="32"
              width="90"
              height="4"/>
            <rect
              x="200"
              y="25"
              width="30"
              height="4"/>
            <rect
              x="200"
              y="32"
              width="90"
              height="4"/>
            <rect
              x="0"
              y="55"
              width="30"
              height="4"/>
            <rect
              x="0"
              y="62"
              width="90"
              height="4"/>
            <rect
              x="200"
              y="55"
              width="30"
              height="4"/>
            <rect
              x="200"
              y="62"
              width="90"
              height="4"/>
          </ContentLoader>
          <template v-else>
            <!--<div class="dropdown">-->
            <!--<button class="btn btn-default dropdown-toggle">-->
            <!--Most Active <span class="caret" />-->
            <!--</button>-->
            <!--</div>-->

            <info-item
              v-for="(info, index) in contactInfo"
              :key="info.label + index"
              :id="info.id"
              :label="info.label"
              :description="info.description"
              :on-delete="deleteContactInfo"
              :on-edit="editContactInfo"/>
            <temp-item v-if="adding" />
            <div
              v-if="!addEnabled && contactInfo.length < 1 && !adding"
              class="col-md-12 collapse">
              <p class="not-editable">No current available information!</p>
            </div>
            <add-info
              v-if="addEnabled"
              heading="Add contact info"
              @save="onAdd"
              @cancel="addEnabled = false"/>
            <div class="add-info">
              <span
                v-if="!addEnabled"
                class="brn btn-text"
                @click="addEnabled = true">Add Info</span>
            </div>
          </template>
        </div>
        <div class="company-profile-ai-gen-ratings-section">
          <gen-ai-ratings
            :rating="ratings.rating"
            @update-rating="editRating"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ContentLoader } from '~/components/custom/vue-content-loader'
import ErrorNotificationMessage from '~/components/common/ErrorNotificationMessage'
import ContactCompany from '~/components/company/ContactCompany'
import AddInfo from './AddInfo'
import TempItem from './TempItem'
import InfoItem from './InfoItem'
import GenAiRatings from '~/components/common/GenAiRatings'

export default {
  components: {
    AddInfo,
    TempItem,
    InfoItem,
    ContentLoader,
    ContactCompany,
    GenAiRatings
  },
  props: {
    ratings: {
      type: Object,
      required: true,
      default: () => ({ rating: 0 })
    },
    isCompanyProfileGenAiEnabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      addEnabled: false,
      adding: false,
      notificationInstance: null
    }
  },
  computed: {
    ...mapState('company/about', {
      contactInfo: state => state.contactInfo
    })
  },
  methods: {
    ...mapActions('company/about', [
      'addContactInfo',
      'editContactInfo',
      'deleteContactInfo'
    ]),
    ...mapActions('company', ['editRating']),
    onAdd({ label, description }) {
      const h = this.$createElement

      if (this.notificationInstance) {
        this.notificationInstance.close()
      }

      if (!label || !description) {
        this.notificationInstance = this.$notify.error({
          message: h(ErrorNotificationMessage, {
            props: { message: 'Title and Description is required' }
          }),
          customClass: 'as-error'
        })

        return
      }

      if (
        this.contactInfo
          .map(t => t.label.toLowerCase())
          .indexOf(label.toLowerCase()) > -1
      ) {
        this.notificationInstance = this.$notify.error({
          message: h(ErrorNotificationMessage, {
            props: { message: 'Contact information already exists' }
          }),
          customClass: 'as-error'
        })

        return
      }

      this.adding = true
      this.addContactInfo({
        label,
        description
      })
        .then(() => {
          this.adding = false
        })
        .catch(() => {
          this.adding = false
        })

      this.addEnabled = false
    }
  }
}
</script>
