<template>
  <section class="company-address">
    <div class="row">
      <div class="col-md-4"><h2>Company Address</h2></div>
      <div class="col-md-8">
        <ContentLoader
          v-if="$actions.pending('company/about/fetchCompanyAbout')"
          :height="60">
          <rect
            x="0"
            y="4"
            width="30"
            height="4"/>
          <rect
            x="0"
            y="11"
            width="90"
            height="4"/>
          <rect
            x="200"
            y="4"
            width="30"
            height="4"/>
          <rect
            x="200"
            y="11"
            width="90"
            height="4"/>
          <rect
            x="0"
            y="34"
            width="30"
            height="4"/>
          <rect
            x="0"
            y="41"
            width="90"
            height="4"/>
          <rect
            x="200"
            y="34"
            width="30"
            height="4"/>
          <rect
            x="200"
            y="41"
            width="90"
            height="4"/>
        </ContentLoader>
        <div>
          <p class="not-editable">
            <template v-if="address">
              {{ address }}
            </template>
            <template v-else>
              Address not available
            </template>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ContentLoader } from '~/components/custom/vue-content-loader'

export default {
  name: 'CompanyAddress',
  components: {
    ContentLoader
  },
  props: {
    address: {
      type: String,
      required: true
    }
  }
}
</script>
